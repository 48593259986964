import { Button, Container, Dialog, DialogContent, DialogContentText, DialogTitle } from '@mui/material';
import { useSnackbar } from 'notistack';
import PropTypes from 'prop-types';

import React from 'react';

NewUserLinkDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  userType: PropTypes.oneOf(['MANAGER', 'ADMIN']),
  link: PropTypes.string,
};

export default function NewUserLinkDialog({ open, handleClose, userType, link }) {
  const { enqueueSnackbar } = useSnackbar();

  return (
    <Dialog open={open} onClose={handleClose}>
      <Container sx={{ minWidth: 300, paddingBottom: 2 }}>
        <DialogTitle>Novo {userType === 'ADMIN' ? 'Administrador' : 'Gerente'}: Link</DialogTitle>
        <DialogContent>
          <DialogContentText>{link}</DialogContentText>
        </DialogContent>
        <Button
          sx={{ marginTop: 2 }}
          size="large"
          variant="contained"
          onClick={() => {
            navigator.clipboard.writeText(link).then(() => {
              enqueueSnackbar('Link copiado', { variant: 'success' });
            });
          }}
        >
          Copiar
        </Button>
      </Container>
    </Dialog>
  );
}
