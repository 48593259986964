import { LoadingButton } from '@mui/lab';
import {
  Card,
  CircularProgress,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import TableEmpty from '../../../../components/TableEmpty';
import Iconify from '../../../../components/Iconify';
import TableFetchError from '../../../../components/TableFetchError';
import Scrollbar from '../../../../components/Scrollbar';
import UserApiKeyV2ListHead from './ApiKeyV2ListHead';
import ApiKeyV2MoreMenu from './ApiKeyV2MoreMenu';
import { USER_API_KEY_TABLE_HEAD } from './ApiKeyV2TableColumns';
import { axiosInstance } from '../../../../utils/axiosInstance';

ApiKeyV2ListForm.propTypes = {
  user: PropTypes.object,
};

export default function ApiKeyV2ListForm({ user }) {
  const {
    isLoading: isLoadingKeys,
    error: errorKeys,
    data: keys,
  } = useQuery(
    ['apiKeysV2', { user_id: user.user_id }],
    () => axiosInstance.get(`v1/users/${user.user_id}/api-keys`).then((res) => res.data),
    {
      enabled: !!user?.user_id,
      retry: false,
    }
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation(() => axiosInstance.get(`v1/users/${user.user_id}/api-keys/new-api-key`), {
    onSuccess: async (data) => {
      await queryClient.cancelQueries(['apiKeysV2', { user_id: user.user_id }]);
      queryClient.setQueryData(['apiKeysV2', { user_id: user.user_id }], (oldData) => [...oldData, data.data]);
      enqueueSnackbar('Sucesso ao criar api key do usuário. Copie a Api e a ApiSecret', {
        variant: 'success',
      });
    },
    onError: () => {
      enqueueSnackbar('Falha ao criar api key do usuário', { variant: 'error' });
    },
  });

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - keys.length) : 0;
  const isKeysNotFound = !keys || keys?.length === 0;

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
        <Typography variant="h4" gutterBottom>
          Api Keys V2
        </Typography>
        <LoadingButton
          onClick={() => {
            mutation.mutate();
          }}
          loading={mutation.isLoading}
          variant="contained"
          startIcon={<Iconify icon="eva:plus-fill" />}
        >
          Nova Api Key
        </LoadingButton>
      </Stack>
      <Card>
        <Scrollbar>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <UserApiKeyV2ListHead headLabel={USER_API_KEY_TABLE_HEAD} />
              {isLoadingKeys ? (
                <CircularProgress />
              ) : (
                <TableBody>
                  {!!keys &&
                    keys.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { apiKey, apiSecret } = row;
                      const isItemSelected = false;

                      return (
                        <TableRow
                          hover
                          key={apiKey}
                          tabIndex={-1}
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {apiKey}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {apiSecret}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="right">
                            <ApiKeyV2MoreMenu user={user} apiKey={apiKey} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>
              )}

              {isKeysNotFound && !isLoadingKeys && !errorKeys && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <TableEmpty searchQuery="Api keys" />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}

              {errorKeys && (
                <TableBody>
                  <TableRow>
                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                      <TableFetchError />
                    </TableCell>
                  </TableRow>
                </TableBody>
              )}
            </Table>
          </TableContainer>
        </Scrollbar>

        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={keys?.length || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Card>
    </>
  );
}
