import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { cpf } from 'cpf-cnpj-validator';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------

EditUserForm.propTypes = {
  user: PropTypes.object,
};

const getCpfNumber = (cpfRaw) => cpfRaw?.replaceAll('.', '').replaceAll('-', '');

export default function EditUserForm({ user }) {
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'O nome é muito curto!').max(50, 'O nome é muito longo!').required('Nome é necessário'),
    email: Yup.string().email('Não é um email válido').required('Email é necessário'),
    role: Yup.mixed().oneOf(['ADMIN', 'MANAGER'], 'Permissão não é válida'),
    cpf: Yup.string()
      .required('CPF é necessário')
      .test('is-cpf-valid', 'CPF não é válido', (value) => {
        const rawCpf = getCpfNumber(value);
        if (!rawCpf || rawCpf?.length !== 11) return false;
        try {
          if (cpf.isValid(rawCpf)) {
            return true;
          }
        } catch {
          return false;
        }
        return false;
      }),
  });
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutation = useMutation((userUpdate) => axiosInstance.put(`/users/${user.user_id}`, userUpdate), {
    onSuccess: () => {
      queryClient.invalidateQueries(['users', 'MANAGER']);
      queryClient.invalidateQueries(['users', 'ADMIN']);
      queryClient.invalidateQueries(['user', { user_id: user.user_id }]).then(() => {
        enqueueSnackbar('Usuário alterado', { variant: 'success' });
      });
    },
    onError: () => {
      enqueueSnackbar('Falha ao alterar usuário', { variant: 'error' });
    },
  });

  const formik = useFormik({
    initialValues: {
      name: user.name,
      email: user.email,
      cpf: cpf.format(user.cpf),
      role: user.role,
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutation.mutate({ ...user, ...values, cpf: getCpfNumber(values.cpf) });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome Completo"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            label="CPF"
            type="text"
            {...getFieldProps('cpf')}
            onChange={(e) => {
              const reg = /^\d+$/;
              const rawCpf = getCpfNumber(e.target.value);
              let finalCpf = rawCpf;
              if (e.target.value.length === 0) {
                setFieldValue('cpf', e.target.value);
              } else if (reg.test(rawCpf) && rawCpf?.length <= 11) {
                if (rawCpf.length > 3) {
                  finalCpf = `${finalCpf.slice(0, 3)}.${finalCpf.slice(3)}`;
                }
                if (rawCpf.length > 6) {
                  finalCpf = `${finalCpf.slice(0, 7)}.${finalCpf.slice(7)}`;
                }
                if (rawCpf.length > 9) {
                  finalCpf = `${finalCpf.slice(0, 11)}-${finalCpf.slice(11)}`;
                }
                setFieldValue('cpf', finalCpf);
              }
            }}
            error={Boolean(touched.cpf && errors.cpf)}
            helperText={touched.cpf && errors.cpf}
          />

          <FormControl fullWidth>
            <InputLabel>Permissão</InputLabel>
            <Select label="Permissão" {...getFieldProps('role')}>
              <MenuItem value={'ADMIN'}>Administrador</MenuItem>
              <MenuItem value={'MANAGER'}>Gerente</MenuItem>
            </Select>
          </FormControl>

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Salvar`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
