import * as Yup from 'yup';
import PropTypes from 'prop-types';
import { cpf } from 'cpf-cnpj-validator';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import { axiosInstance } from '../../../utils/axiosInstance';

// ----------------------------------------------------------------------

NewUserForm.propTypes = {
  userType: PropTypes.oneOf(['MANAGER', 'ADMIN']),
  onSubmited: PropTypes.func,
};

const getCpfNumber = (cpfRaw) => cpfRaw?.replaceAll('.', '').replaceAll('-', '');

export default function NewUserForm({ userType, onSubmited }) {
  const RegisterSchema = Yup.object().shape({
    name: Yup.string().min(2, 'O nome é muito curto!').max(50, 'O nome é muito longo!').required('Nome é necessário'),
    email: Yup.string().email('Não é um email válido').required('Email é necessário'),
    cpf: Yup.string()
      .required('CPF é necessário')
      .test('is-cpf-valid', 'CPF não é válido', (value) => {
        const rawCpf = getCpfNumber(value);
        if (!rawCpf || rawCpf?.length !== 11) return false;
        try {
          if (cpf.isValid(rawCpf)) {
            return true;
          }
        } catch {
          return false;
        }
        return false;
      }),
  });
  const queryClient = useQueryClient();
  const { enqueueSnackbar } = useSnackbar();

  const mutation = useMutation((newUser) => axiosInstance.post('/users', newUser), {
    onSuccess: (data) => {
      queryClient.setQueryData(['users', userType], (oldData) => [...oldData, data.data.user]);
      enqueueSnackbar('Usuário criado', { variant: 'success' });
      onSubmited(data.data.passwordLink);
    },
    onError: () => {
      enqueueSnackbar('Falha ao criar usuário, verifique se o email ou cpf já existe', { variant: 'error' });
    },
  });

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      cpf: '',
    },
    validationSchema: RegisterSchema,
    onSubmit: (values) => {
      mutation.mutate({ ...values, cpf: getCpfNumber(values.cpf), role: userType });
    },
  });

  const { errors, touched, handleSubmit, getFieldProps, setFieldValue } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3}>
          <TextField
            fullWidth
            label="Nome Completo"
            {...getFieldProps('name')}
            error={Boolean(touched.name && errors.name)}
            helperText={touched.name && errors.name}
          />

          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <TextField
            fullWidth
            label="CPF"
            type="text"
            {...getFieldProps('cpf')}
            onChange={(e) => {
              const reg = /^\d+$/;
              const rawCpf = getCpfNumber(e.target.value);
              let finalCpf = rawCpf;
              if (e.target.value.length === 0) {
                setFieldValue('cpf', e.target.value);
              } else if (reg.test(rawCpf) && rawCpf?.length <= 11) {
                if (rawCpf.length > 3) {
                  finalCpf = `${finalCpf.slice(0, 3)}.${finalCpf.slice(3)}`;
                }
                if (rawCpf.length > 6) {
                  finalCpf = `${finalCpf.slice(0, 7)}.${finalCpf.slice(7)}`;
                }
                if (rawCpf.length > 9) {
                  finalCpf = `${finalCpf.slice(0, 11)}-${finalCpf.slice(11)}`;
                }
                setFieldValue('cpf', finalCpf);
              }
            }}
            error={Boolean(touched.cpf && errors.cpf)}
            helperText={touched.cpf && errors.cpf}
          />

          <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={mutation.isLoading}>
            {`Criar ${userType === 'ADMIN' ? 'Administrador' : 'Gerente'}`}
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  );
}
