import { filter } from 'lodash';
import { sentenceCase } from 'change-case';
import { cpf as cpfLib } from 'cpf-cnpj-validator';
import { useState } from 'react';
// material
import {
  Card,
  Table,
  Stack,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  CircularProgress,
} from '@mui/material';
// components
import { useQuery } from 'react-query';
import NewUserLinkDialog from '../sections/@dashboard/user/NewUserLinkDialog';
import { axiosInstance } from '../utils/axiosInstance';
import NewUserDialog from '../sections/@dashboard/user/NewUserDialog';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
import SearchNotFound from '../components/SearchNotFound';
import { UserListHead, UserListToolbar, UserMoreMenu } from '../sections/@dashboard/user';
import { USER_TABLE_HEAD } from '../sections/@dashboard/user/UserTableColumns';
import TableFetchError from '../components/TableFetchError';

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  if (!array) return [];
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function Managers() {
  const [page, setPage] = useState(0);

  const [order, setOrder] = useState('asc');

  const [orderBy, setOrderBy] = useState('name');

  const [filterName, setFilterName] = useState('');

  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [newUserDialogVisible, setNewUserDialogVisible] = useState(false);
  const [newUserLinkDialogVisible, setNewUserLinkDialogVisible] = useState(false);
  const [newUserLink, setNewUserLink] = useState('');

  const { isLoading, error, data } = useQuery(['users', 'MANAGER'], () =>
    axiosInstance.get('/users?role=MANAGER').then((res) => res.data)
  );

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const filteredUsers = applySortFilter(data, getComparator(order, orderBy), filterName);

  const isUserNotFound = filteredUsers.length === 0;

  return (
    <Page title="Managers">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Gerentes
          </Typography>
          <Button
            onClick={() => setNewUserDialogVisible(true)}
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Novo Gerente
          </Button>
        </Stack>

        <Card>
          <UserListToolbar numSelected={0} filterName={filterName} onFilterName={handleFilterByName} />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={USER_TABLE_HEAD}
                  onRequestSort={handleRequestSort}
                />
                {isLoading ? (
                  <CircularProgress />
                ) : (
                  <TableBody>
                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                      const { name, email, cpf, active } = row;
                      const isItemSelected = false;

                      return (
                        <TableRow
                          hover
                          key={row.user_id}
                          tabIndex={-1}
                          selected={isItemSelected}
                          aria-checked={isItemSelected}
                        >
                          <TableCell component="th" scope="row">
                            <Stack direction="row" alignItems="center" spacing={2}>
                              <Typography variant="subtitle2" noWrap>
                                {name}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell align="left">{email}</TableCell>
                          <TableCell align="left">{cpfLib.format(cpf)}</TableCell>
                          <TableCell align="left">
                            <Label variant="ghost" color={(!active && 'error') || 'success'}>
                              {!active ? sentenceCase('Inativo') : sentenceCase('Ativo')}
                            </Label>
                          </TableCell>

                          <TableCell align="right">
                            <UserMoreMenu user={row} />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                )}

                {isUserNotFound && !isLoading && !error && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}

                {error && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <TableFetchError />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={filteredUsers.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>

        <NewUserDialog
          userType="MANAGER"
          open={newUserDialogVisible}
          handleClose={(link) => {
            setNewUserDialogVisible(false);
            console.log(link);
            if (link) {
              setNewUserLink(link);
              setNewUserLinkDialogVisible(true);
            }
          }}
        />

        <NewUserLinkDialog
          userType="MANAGER"
          open={newUserLinkDialogVisible}
          link={newUserLink}
          handleClose={() => {
            setNewUserLinkDialogVisible(false);
          }}
        />
      </Container>
    </Page>
  );
}
