// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'Início',
    path: '/dashboard/',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'Administradores',
    path: '/dashboard/admins',
    icon: getIcon('eos-icons:admin'),
  },
  {
    title: 'Gerentes',
    path: '/dashboard/managers',
    icon: getIcon('bxs:user'),
  },
];

export default navConfig;
