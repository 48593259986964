// material
import { Box, Card, Container, Typography } from '@mui/material';
// components
import { useQuery } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import ProductList from '../sections/@dashboard/user/Product/ProductList';
import ApiKeyListForm from '../sections/@dashboard/user/ApiKey/ApiKeyListForm';
import { axiosInstance } from '../utils/axiosInstance';
import Page from '../components/Page';
import EditUserForm from '../sections/@dashboard/user/EditUserForm';
import ApiKeyV2ListForm from '../sections/@dashboard/user/ApiKeyV2/ApiKeyV2ListForm';

// ----------------------------------------------------------------------

export default function User() {
  const params = useParams();
  const navigation = useNavigate();

  const { isLoading: isLoadingUser, data: user } = useQuery(['user', { user_id: params.userId }], () =>
    axiosInstance
      .get(`/users/${params.userId}`)
      .then((res) => res.data)
      .catch(() => {
        navigation('404', { replace: true });
      })
  );

  return (
    <Page title="User">
      <Container>
        {!isLoadingUser && (
          <>
            <Typography variant="h3" gutterBottom>
              {user?.name}
            </Typography>
            <Card sx={{ padding: 2, mb: 4 }}>
              <EditUserForm user={user} />
            </Card>
          </>
        )}
        {!isLoadingUser && <ProductList user={user} />}
        <Box sx={{ my: 5 }} />
        {!isLoadingUser && <ApiKeyV2ListForm user={user} />}
        <Box sx={{ my: 5 }} />
        {!isLoadingUser && <ApiKeyListForm user={user} />}
      </Container>
    </Page>
  );
}
