import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Login from './pages/Login';
import NotFound from './pages/Page404';
import DashboardApp from './pages/DashboardApp';
import Admins from './pages/Admins';
import Managers from './pages/Managers';
import User from './pages/User';
import ResetPassword from './pages/ResetPassword';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { path: '', element: <DashboardApp /> },
        { path: 'admins', element: <Admins /> },
        { path: 'managers', element: <Managers /> },
        { path: 'user/:userId', element: <User /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    // {
    //   path: '/create_account_link',
    //   element: <LogoOnlyLayout />,
    //   children: [
    //     { path: '', element: <Navigate to="/404" /> },
    //     { path: ':userid/:tokenuid', element: <NewUserLink /> },
    //     { path: '*', element: <Navigate to="/404" /> },
    //   ],
    // },
    {
      path: '/',
      element: <LogoOnlyLayout />,
      children: [
        { path: '', element: <Navigate to="/login/" /> },
        { path: 'login', element: <Login /> },
        { path: 'reset_password', element: <ResetPassword /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
