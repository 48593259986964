import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';
import { useFormik, Form, FormikProvider } from 'formik';
// material
import { Stack, TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../../../firebase/FirebaseConnector';
// component

// ----------------------------------------------------------------------

export default function ResetPasswordForm() {
  const navigation = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const LoginSchema = Yup.object().shape({
    email: Yup.string().email('O Email precisa ser válido').required('O Email é necessário'),
  });

  const formik = useFormik({
    initialValues: {
      email: '',
    },
    validationSchema: LoginSchema,
    onSubmit: (values) => {
      sendPasswordResetEmail(auth, values.email, { url: process.env.REACT_APP_FIREBASE_PASSWORD_RESET_REDIRECT })
        .then(() => {
          enqueueSnackbar('Foi enviado um link de redefinição para seu email.', { variant: 'success' });
          navigation('/');
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar('Algo deu errado.Verifique se digitou seu email corretamente.', { variant: 'error' });
        })
        .finally(() => {
          setSubmitting(false);
        });
    },
  });

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps, setSubmitting } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} sx={{ my: 2 }}>
          <TextField
            fullWidth
            autoComplete="username"
            type="email"
            label="Email"
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
        </Stack>

        <LoadingButton fullWidth size="large" type="submit" variant="contained" loading={isSubmitting}>
          Enviar
        </LoadingButton>
      </Form>
    </FormikProvider>
  );
}
