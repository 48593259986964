/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

const withAuth = (WrappedComponent) => (props) => {
  const [proceed, setProceed] = useState(false);
  const navigation = useNavigate();
  const isSignedIn = useSelector((state) => state.user.isSignedIn);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (!isSignedIn) {
        navigation('../', { replace: true });
      }
    }
    setProceed(true);
  }, []);

  if (proceed) return <WrappedComponent {...props} />;
  return null;
};

export default withAuth;
