import { createSlice } from '@reduxjs/toolkit';

export const defaultUserState = {
  user: {
    uid: '',
    internal_id: '',
    email: '',
    name: '',
    cpf: '',
    role: '',
    token: '',
    refreshToken: '',
    expirationTime: '',
    active: false,
  },
  isSignedIn: false,
};

export const userSlice = createSlice({
  name: 'user',
  initialState: defaultUserState,
  reducers: {
    signInUser(state, action) {
      state.user = action.payload;
      state.isSignedIn = true;
    },
    signOutUser(state) {
      state.user = defaultUserState.user;
      state.isSignedIn = false;
    },
  },
});

// Extract the action creators object and the reducer
const { actions, reducer } = userSlice;
// Extract and export each action creator by name
export const { signInUser, signOutUser } = actions;

export default reducer;
