import { useRef, useState } from 'react';
import PropTypes from 'prop-types';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import { useMutation, useQueryClient } from 'react-query';
import { useSnackbar } from 'notistack';
import Iconify from '../../../../components/Iconify';
import { axiosInstance } from '../../../../utils/axiosInstance';

// ----------------------------------------------------------------------
ApiKeyMoreMenu.propTypes = {
  user: PropTypes.object,
  apiKey: PropTypes.string,
};

export default function ApiKeyMoreMenu({ user, apiKey }) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const queryClient = useQueryClient();

  const mutationDelete = useMutation(() => axiosInstance.delete(`/users/${user.user_id}/api-keys/${apiKey}`), {
    onSuccess: () => {
      queryClient.invalidateQueries(['apiKeys', { user_id: user.user_id }]).then(() => {
        enqueueSnackbar('Api Key deletada.', { variant: 'success' });
      });
    },
    onError: () => {
      enqueueSnackbar('Error ao tentar deletar api key.', { variant: 'error' });
    },
  });

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          sx={{ color: 'text.secondary' }}
          onClick={() => {
            mutationDelete.mutate();
            setIsOpen(false);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Deletar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
