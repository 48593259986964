import PropTypes from 'prop-types';
// material
import { Paper, Typography } from '@mui/material';

// ----------------------------------------------------------------------

TableEmpty.propTypes = {
  searchQuery: PropTypes.string,
};

export default function TableEmpty({ searchQuery = '', ...other }) {
  return (
    <Paper {...other}>
      <Typography gutterBottom align="center" variant="subtitle1">
        Nenhum <strong>&quot;{searchQuery}&quot;</strong> cadastrado ainda.
      </Typography>
    </Paper>
  );
}
