import { Container, Dialog, DialogTitle } from '@mui/material';
import PropTypes from 'prop-types';

import React from 'react';
import NewUserForm from './NewUserForm';

NewUserDialog.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  userType: PropTypes.oneOf(['MANAGER', 'ADMIN']),
};

export default function NewUserDialog({ open, handleClose, userType }) {
  return (
    <Dialog open={open} onClose={() => handleClose('')}>
      <Container sx={{ minWidth: 300, paddingBottom: 2 }}>
        <DialogTitle>Novo {userType === 'ADMIN' ? 'Administrador' : 'Gerente'}</DialogTitle>
        <NewUserForm userType={userType} onSubmited={handleClose} />
      </Container>
    </Dialog>
  );
}
